import React, { FunctionComponent, useEffect, useState } from "react"
import ArticleCard from "../../../components/ArticleCard"
import "../../../assets/styles/page/docArticle.scss"
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo/seo"

interface Article {
  titleOfArticle: string;
  metaTitle: string;
  metaDescription: string;
  bannerUrl: {
    url: string;
  };
  slug: string;
  _id: string;
  seo: {
    title: string;
    description: string;
    image: {
      url: string;
    };
  };
}

const Article: FunctionComponent = () => {
  const [articles, setArticles] = useState<Article[]>([])
  const [metaData, setMetaData] = useState<Article['seo'] | null>(null)

  useEffect(() => {
    const fetchArticles = async () => {
      const query = `
        query articleInfo {
          articleCollection {
            items {
              seo {
                ... on Seo {
                  description
                  title
                  image {
                    url
                  }
                }
              }
              ... on Article {
                titleOfArticle
                metaDescription
                metaTitle
                slug
                _id
                bannerUrl {
                  url
                }
              }
            }
          }
        }
      `
      try {
        const { default: ContentfulService } = await import("../../../services/contentful.service")
        const response = await ContentfulService.callContentfulApi(query)
        const articles = response.data.articleCollection?.items || []
        setArticles(articles)
        setMetaData(articles[0]?.seo)
      } catch (error) {
        console.error('Error fetching articles:', error)
      }
    }

    fetchArticles()
  }, [])

  if (!articles.length || !metaData) {
    return <div>Loading...</div>
  }

  return (
    <div>
      <SEO title={metaData.title} description={metaData.description} image={metaData.image.url} />
      <div>
        <Layout>
          <div className="articles--page--cointainer">
            <div className="article--header">
              <h1 className="articles--title">Articles</h1>
              <p className="articles--title--subtitles">
                Explore our community features, case studies, opinion pieces, and more
              </p>
            </div>
            <div className="article--list">
              {articles.map(article => {
                const { titleOfArticle, metaTitle, metaDescription, bannerUrl, slug, _id } = article
                return (
                  <Link to={`/doc/article/doc-article/${slug}`} className="artile--card--wrapper" key={_id}>
                    <ArticleCard
                      key={_id}
                      articleImage={bannerUrl.url}
                      articleHeadtext={titleOfArticle}
                      articleSubtext={metaTitle}
                      meta={metaDescription}
                    />
                  </Link>
                )
              })}
            </div>
          </div>
        </Layout>
      </div>
    </div>
  )
}

export default Article
